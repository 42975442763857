import React from "react"
import Layout from "../../components/h/layout"

const HalloweenEPage = () => (
  <Layout>
    <h2>This is where your sweets are found.</h2>
    <h2>I’m a pumpkin with a handle, pink and round.</h2>
  </Layout>
)

export default HalloweenEPage
